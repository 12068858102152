<template>
	<div>
		<Header ref="Header"/>
		<img :src="menuPic" class="Big-title"/>
		<div class="container">
			<div class="park-list">
				<div v-for="(item,index) in list" :key="index">
					<div class="park-list-item">
						<div class="park-list-up flex">
							<div class="park-list-left">
								<el-carousel :interval="4000" height="412px" :ref="'swiper'+index" arrow="always">
								    <el-carousel-item v-for="(unit,index) in item.carouselList" :key="index">
										<img :src="unit.picture" class="park-list-pic"/>
								    </el-carousel-item>
								</el-carousel>
							</div>
							<div class="park-list-right">
								<div class="park-list-name">{{item.name}}</div>
								<div class="park-list-place flex">
									<img class="park-list-loc" src="../../public/images/place_01.png" />
									<div class="park-list-address">{{item.address}}</div>
								</div>
								<div class="park-list-desc" v-html="item.brief"></div>
								<div>
									<div class="park-list-view flex">
										<img class="park-list-fire" src="../../public/images/fire_01.png">
										<div class="park-list-num">浏览 {{item.viewCount}}</div>
									</div>
								</div>
								<div class="park-list-bigBtn jump" @click="consult(item.id,item.name)">立即咨询</div>
							</div>
						</div>
						<div class="park-list-down">
							<div class="park-down-title">园区介绍</div>
							<div class="park-down-text" v-html="item.detail"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Notification, MessageBox, Message } from 'element-ui'
	import  Header from '@/components/header.vue';
	export default {
		components:{Header},
		data() {
			return {
				menuPic:'',
				postData:{
					pageNum:1,
					pageSize:4,
				},
				list:[],
			}
		},
		mounted() {
			this.$refs.Header.setIndex(5);
			// 获取菜单图片
			this.$api.getMenuPic('园区招商').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			this.$api.getParkList(this.postData).then(res=>{
				console.log(res,'园区招商列表');
				this.list=res.rows;
			})
		},
		methods: {
			consult(id,name){
				var data={
					placeId:id,
					placeName:name,
				}
				this.$api.addrRecord(data).then(res=>{
					console.log(res,'增加咨询记录')
					if(res){
						MessageBox.confirm('系统已经收到您的咨询申请，我们将尽快与您联系', '系统提示', {
						        confirmButtonText: '确定',
						        type: 'success'
						    }
						)
					}
				})
			},
		},
	};
</script>

<style scoped>
	
</style>
